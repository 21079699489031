import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import moment from "moment"

import { getExcerpt } from "src/utils/posts"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import Content from "src/components/Content"
import RelatedInsights from "src/components/insights/Related"

import logoMark from "src/assets/images/vaporware-logo-mark.svg"
import "./mediaTemplate.scss"

const MediaTemplate = ({data: {page: {
  html, frontmatter: {
  meta, speaker, organization, media, categories, tags,
}}}}) => {
  
  const excerpt = getExcerpt({text: html, characters: 160})
  const newMeta = { 
    ...meta,
    description: meta.description || excerpt,
    image: meta.image || {childImageSharp: {fluid: { src:"/img/card-default-post.png"}}},
  }

  const player = media.type === "video" ? (
    <div className="video-container">
      <iframe
        title={media.type}
        width="560" 
        height="315" 
        src={media.url}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
        frameBorder="0"
      />
    </div>
  ) : (
    <div className="audio-container">
      <iframe 
        title={media.type}
        src={media.url} 
        width="100%" 
        height="198px" 
        frameBorder="0" 
        allow="encrypted-media"
        scrolling="no"
      />
    </div>
  )
  
  return (
    <Layout meta={newMeta}>
      <div className="media-template">
        <PageHero
          className={media.type}
          overlay={false}
        />
        <div className={`media-hero ${media.type}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                {player}
              </div>
            </div>
          </div>
        </div>
      
        <div className="media-content">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h1>{meta.title}</h1>
                <h5>
                  A talk by{" "}
                  {speaker.url ?
                    <a href={speaker.url} target="_blank" rel="noopener noreferrer">{speaker.name}</a>
                    :
                    <span>{speaker.name}</span>
                  }
                  {" "}for{" "}
                  {organization.url ?
                    <a href={organization.url} target="_blank" rel="noopener noreferrer">{organization.name}</a>
                    :
                    <span>{organization.name}</span>
                  }
                  {` on ${moment(meta.date).format("LL")}`}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <Content html={html} />
                {media.original &&
                  <>
                  <hr />
                    <em>
                      This talk originally appeared{" "}
                      <a href={media.original} target="_blank" rel="noopener noreferrer">here</a>
                      {" "}and has been posted with permission from{" "}
                      {organization.url ?
                        <a href={organization.url} target="_blank" rel="noopener noreferrer">{organization.name}</a>
                        :
                        <span>{organization.name}</span>
                      }
                      .
                    </em>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
     
        <div className="post-mark">
          <Link to="/insights">
            <img src={logoMark} alt="Vaporware" />
          </Link>
        </div>

        <section className="related-insights">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h2 className="h1 text-center">Related Insights</h2>
              </div>
            </div>
            <RelatedInsights categories={categories} tags={tags} currentId={meta.id} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

MediaTemplate.defaultProps = {
  data: {
    page: {
      frontmatter: {
        meta: {},
        speaker: {},
        organization: {},
        media: {},
        categories: [],
        tags: [],
      },
      html: "",
    },
  },
}

MediaTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.object,
        speaker: PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        }),
        organization: PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        }),
        media: PropTypes.shape({
          type: PropTypes.string,
          url: PropTypes.string,
          original: PropTypes.string,
        }),
        categories: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
      }),
      html: PropTypes.string,
    }),
  }),
}

export default MediaTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      frontmatter {
        title
        speaker {
          name
          url
        }
        organization {
          name
          url
        }
        media {
          type
          url
          original
        }
        categories
        tags
      }
    }
  }
`
